import React from 'react'

import { Query } from 'react-apollo/index'
import gql from 'graphql-tag'

import DataContainer from './DataContainer'

export const GET_CONVERSATIONS = gql`
  query ConversationsQuery {
    activeUser {
      id
      fullName
      image
    }
    conversations {
      id
      users {
        id
        fullName
        image
      }
      unreadBy {
        id
      }
      archivedBy {
        id
      }
      messages {
        id
        user {
          id
          image
        }
        date
        text
        attachment
      }
    }
  }
`

class MessengerApp extends React.Component {
  renderLoading() {
    return <div className="ajax-overlay" style={{ display: 'block' }} />
  }

  render() {
    return (
      <Query query={GET_CONVERSATIONS}>
        {({ loading, error, data }) => {
          if (loading || !data) {
            return this.renderLoading()
          }
          if (error) {
            return <p>{error.toString()}</p>
          }
          return <DataContainer data={data} />
        }}
      </Query>
    )
  }
}

export default MessengerApp
