import React, { Component } from 'react'
import { FieldValue, SelectChoice } from './types'
import { Radio } from '../bs'
import Query from 'react-apollo/Query'
import LoadingSpinner, { LoadingSpinnerSizes } from './LoadingSpinner'

interface Props {
  id: string
  onChangeValue: (name: string, value: FieldValue) => void
  inline?: boolean
  value?: FieldValue
  onChangeOtherValue?: (name: string, value: FieldValue) => void
  otherValue?: FieldValue
}
interface InnerProps extends Props {
  choices: SelectChoice[]
}
interface OuterProps extends Props {
  choices: SelectChoice[] | any
  choiceMap?: (queryResult: any) => SelectChoice[]
}

class RadioInputInner extends Component<InnerProps> {
  render() {
    let {
      onChangeOtherValue,
      choices,
      otherValue,
      inline,
      id,
      onChangeValue,
      value,
    } = this.props
    return (
      <React.Fragment>
        {choices.map((choice: SelectChoice) => (
          <Radio
            key={choice.value}
            inline={inline}
            htmlFor={`${id}_choice_${choice.value}`}
            style={{ fontWeight: 400 }}
          >
            <input
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangeValue(e.target.name, e.target.value)
              }
              type="radio"
              id={`${id}_choice_${choice.value}`}
              name={id}
              value={choice.value}
              checked={value === choice.value}
            />
            <span style={{ paddingRight: 10, paddingLeft: 10 }}>
              {choice.label}
            </span>
          </Radio>
        ))}
        {onChangeOtherValue !== undefined && (
          <Radio
            key={'other'}
            inline={inline}
            htmlFor={`${id}_choice_other`}
            style={{ fontWeight: 400 }}
          >
            <input
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChangeValue(e.target.name, e.target.value)
              }
              type="radio"
              id={`${id}_choice_other`}
              value={'other'}
              name={id}
              checked={value === 'other'}
            />
            <input
              type={'text'}
              name={`${id}Other`}
              id={`${id}_other`}
              value={otherValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChangeValue(id, 'other')
                onChangeOtherValue!(e.target.name, e.target.value)
              }}
            />
          </Radio>
        )}
      </React.Fragment>
    )
  }
}

export default class RadioInput extends Component<OuterProps> {
  render() {
    const { choices, choiceMap, ...other } = this.props
    if (Array.isArray(choices))
      return <RadioInputInner {...other} choices={choices} />
    else
      return (
        <Query<any> query={choices}>
          {({ loading, data }) => {
            if (!loading && choiceMap)
              return (
                <RadioInputInner
                  choices={choiceMap(data)}
                  {...other}
                />
              )
            else return <LoadingSpinner size={LoadingSpinnerSizes.small} />
          }}
        </Query>
      )
  }
}
