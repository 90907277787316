import React, { Component } from 'react'
import { Col, Container, Row } from '../bs'
import { theme } from '../../theme'
import { Div, H1, H3, ColoredList, ColoredLI, P } from '../common'
import styled from '@emotion/styled'
import HWWHeadline from './HWWHeadline'

const LocalH1 = styled(H1)`
  border-bottom: 10px solid ${theme.pubSecondary1};
  display: inline-block;
  margin: 80px 0 20px;
`
const LocalH3 = styled(H3)`
  margin-top: 0;
`
const Strong = styled('strong')`
  margin-right: 4px;
`

class HowWeWorkPage extends Component {
  render() {
    return (
      <React.Fragment>
        <Div backgroundColor={theme.pubPrimary2}>
          <Container>
            <Row>
              <Col mdOffset={2}>
                <LocalH1
                  fontSize={theme.fontSizeBigHeading}
                  color={theme.white}
                  uppercase
                >
                  How
                  <br />
                  we work
                </LocalH1>
              </Col>
            </Row>
          </Container>
        </Div>
        <HWWHeadline backgroundColor={theme.pubSecondary1} id={'author'}>
          You're an author
        </HWWHeadline>
        <Container style={{ marginBottom: 80 }}>
          <Row>
            <Col md={2} mdOffset={2}>
              <LocalH3 uppercase>Launch a book crowdfunding campaign</LocalH3>
            </Col>
            <Col md={6}>
              <P>
                You have a great book idea and might need a little push and some
                support to get it out there. We can help you transform it into a
                best-seller by connecting you with key players in the publishing
                industry.
              </P>
              <P>Signing up and getting published is a four step process:</P>
              <ColoredList bulletColor={theme.pubSecondary1}>
                <ColoredLI>
                  <Strong>Pitch your book idea to us.</Strong>
                  One of our scouts will receive your pitch and guide you
                  through creating a book proposal and planning a
                  high-performing crowdfunding campaign.
                </ColoredLI>
                <ColoredLI>
                  <Strong>Submit your book proposal.</Strong>
                  Use our step-by-step book proposal writing template and
                  helpful resources to meet high-level industry criteria. Your
                  scout will team up with you to get approval from our editorial
                  team.
                </ColoredLI>
                <ColoredLI>
                  <Strong>Launch your book crowdfunding campaign.</Strong>
                  It is your responsibility to market and bring traction to your
                  book idea using unique bonuses and perks. The more pre-order
                  copies you sell, the more likely it is that editors will
                  express interest in your book idea.
                </ColoredLI>
                <ColoredLI>
                  <Strong>Raise funds to publish.</Strong>
                  Because you are actually selling pre-order copies of your book
                  idea, you’ll earn funds from your readers. You earn 70% on
                  your total funds raised, while Publishizer earns 30% and
                  provides you with connections to editors and publishers. You
                  will be paid once, after the end of your campaign and upon
                  submitting your bank details and a W-9 form for tax purposes.
                  You will then be responsible for fulfilling any pre-order
                  purchases.
                </ColoredLI>
              </ColoredList>
            </Col>
          </Row>
          <Row>
            <Col md={2} mdOffset={2}>
              <LocalH3 uppercase>Sign a publishing deal</LocalH3>
            </Col>
            <Col md={6}>
              <P>
                Once your book crowdfunding campaign is over, based on the
                outcome, one of three things may happen:
              </P>
              <ColoredList bulletColor={theme.pubSecondary1}>
                <ColoredLI>
                  <Strong>You sell at least 1,000 order copies.</Strong>
                  Publishizer could very likely get interest from an editor at a
                  traditional publishing house on your book idea. In that case,
                  you also qualify to be fully agented by Publishizer. If you
                  sign a traditional publishing deal, then, subject to the terms
                  of that deal, your publisher will fulfill your pre-order
                  copies sold in the book crowdfunding campaign.
                </ColoredLI>
                <ColoredLI>
                  <Strong>You sell at least 500 order copies.</Strong>
                  Publishizer could very likely get interest from an editor at a
                  traditional publishing house for your book idea. Your scout
                  will assist you in completing a full brief for editors, and
                  will help with the negotiation process. If Publishizer is able
                  to secure a traditional publishing deal then your publisher,
                  subject to the terms of the deal, will fulfill your pre-order
                  copies sold in the book crowdfunding campaign.
                </ColoredLI>
                <ColoredLI>
                  <Strong>You sell less than 500 order copies.</Strong>
                  If the minimum goal is not reached, then it is the sole
                  responsibility of the author to fulfill the pre-orders placed
                  throughout the book crowdfunding campaign. Publishizer may
                  still choose to represent you as an agent or a deal creator,
                  however. But if not, Publishizer allows you to connect with
                  reputable hybrid and service publishers. You can message them
                  directly and take steps required to obtain and sign a
                  publishing contract. Or you can self-publish.
                </ColoredLI>
              </ColoredList>
            </Col>
          </Row>
          <Row>
            <Col md={2} mdOffset={2}>
              <LocalH3 uppercase>Publishizer as your literary agent</LocalH3>
            </Col>
            <Col md={6}>
              <P>
                If you sell over 1,000 pre-order copies, you qualify to be fully
                represented by Publishizer. That means we would like to support
                your authoring career, and you have the potential to become
                fully agented under the industry’s standard commissions:
                Publishizer will earn an additional 15% on domestic rights and
                20% on foreign rights, subject to additional terms.
              </P>
            </Col>
          </Row>
          <Row>
            <Col md={2} mdOffset={2}>
              <LocalH3 uppercase>
                Publishizer as your initial deal creator
              </LocalH3>
            </Col>
            <Col md={6}>
              <P>
                If you sell over 500 pre-order copies, Publishizer could very
                likely create a traditional publishing deal for you and assist
                you throughout the negotiation process. As your initial deal
                creator, Publishizer will not earn a commission on the advance
                or royalties. Publishizer will not act as your agent on an
                ongoing basis on this deal, it will simply negotiate the initial
                contract with the Publisher and work with the Publisher to help
                fulfill pre-orders. You have the right to obtain literary
                representation elsewhere.
              </P>
            </Col>
          </Row>
        </Container>
        <HWWHeadline backgroundColor={theme.pubSecondary2} id={'editor'}>
          You're an editor
        </HWWHeadline>
        <Container style={{ marginBottom: 80 }}>
          <Row>
            <Col md={2} mdOffset={2}>
              <LocalH3 uppercase>Discover new books for your list</LocalH3>
            </Col>
            <Col md={6}>
              <P>
                You’re looking for a reliable partner to disrupt the publishing
                industry. It’s about time! We bring you book ideas with a strong
                readership so that you can make more profitable editorial
                acquisitions. Don’t miss all the benefits of signing up for a
                publisher profile.
              </P>
              <P>Signing up and receiving queries takes no time at all:</P>
              <ColoredList bulletColor={theme.pubSecondary2}>
                <ColoredLI>
                  <Strong>Create a free publisher profile.</Strong>
                  All of your information is private. Only our scouts can see
                  your bio and criteria. Choose your categories, keywords,
                  location and other details so we get to know as much as
                  possible about your list.
                </ColoredLI>
                <ColoredLI>
                  <Strong>Receive targeted queries.</Strong>
                  Based on your given information, you will start receiving
                  targeted queries only when we find the perfect fit for your
                  list! We do not spam. You can opt out or unsubscribe at any
                  time.
                </ColoredLI>
                <ColoredLI>
                  <Strong>Express interest.</Strong>
                  Browse available book proposals and express interest in
                  authors you’d like to work with. You may also request more
                  information about the manuscript. One of our scouts will
                  connect with you to follow up.
                </ColoredLI>
              </ColoredList>
            </Col>
          </Row>
          <Row>
            <Col md={2} mdOffset={2}>
              <LocalH3 uppercase>Acquire new books for your list</LocalH3>
            </Col>
            <Col md={6}>
              <P>
                The outcome of an author’s book crowdfunding campaign is a great
                indicator of a book’s potential in the market.
              </P>
              <ColoredList bulletColor={theme.pubSecondary2}>
                <ColoredLI>
                  <Strong>Are you a traditional publisher?</Strong>
                  We will only query you if an author sells at least 500
                  pre-order copies. At this level, a Publishizer scout will
                  assist the authors in all communications and negotiations
                  until a publishing contract is signed. If Publishizer is fully
                  agenting for an author, expect the traditional editor-agent
                  interaction. Subject to the terms of you contract with the
                  author, you will fulfill the pre-orders raised during the
                  crowdfunding campaign. We provide confidential shipping
                  information. This must be stipulated contractually in your
                  agreement with the author.
                </ColoredLI>
                <ColoredLI>
                  <Strong>Are you a hybrid/service publisher?</Strong>
                  You will be queried based on your selected preferences. You
                  will be connected directly with the authors
                </ColoredLI>
              </ColoredList>
            </Col>
          </Row>
        </Container>
        <HWWHeadline backgroundColor={theme.pubSecondary3} id={'reader'}>
          You're a reader
        </HWWHeadline>
        <Container>
          <Row>
            <Col md={2} mdOffset={2}>
              <LocalH3 uppercase>
                Discover and support new books and authors
              </LocalH3>
            </Col>
            <Col md={6}>
              <P>Signing up and pre-ordering new books is super easy:</P>
              <ColoredList bulletColor={theme.pubSecondary3}>
                <ColoredLI>
                  <Strong>Create a free reader profile.</Strong>
                  All of your information is private. Choose your favorite
                  categories so we can share new live and available books and
                  all the great bonuses offered.
                </ColoredLI>
                <ColoredLI>
                  <Strong>Interact with authors and readers.</Strong>
                  Comment on updates from authors about their books and unique
                  bonuses directly on the reader feed in your profile.
                </ColoredLI>
                <ColoredLI>
                  <Strong>Receive staff picks.</Strong>
                  Based on your category subscriptions, you will receive
                  trending book crowdfunding campaigns. We do not spam. You can
                  opt out or unsubscribe at any time.
                </ColoredLI>
                <ColoredLI>
                  <Strong>Pre-order books.</Strong>
                  Browse book crowdfunding campaigns and pre-order as many books
                  as you want! Payment is due upon pre-order purchase and
                  includes the cost of shipping. Because of the unique nature of
                  crowdfunding, authors offer exclusive bonuses and perks to
                  readers for supporting their campaigns or for buying multiple
                  copies at once. You will be directly helping an author get a
                  publisher for their book! The author remains solely
                  responsible for fulfilling pre-orders if that obligation isn't
                  assumed by a publisher.
                </ColoredLI>
              </ColoredList>
            </Col>
          </Row>
          <Row>
            <Col md={2} mdOffset={2}>
              <LocalH3 uppercase>Receive your books</LocalH3>
            </Col>
            <Col md={6}>
              <P>
                Receive your pre-ordered copies when the author signs with a
                publisher and releases their books for print:
              </P>
              <ColoredList bulletColor={theme.pubSecondary3}>
                <ColoredLI>
                  If an author you support signs a traditional publishing
                  contract, it is the publisher's obligation to fulfill
                  pre-order copies.
                </ColoredLI>
                <ColoredLI>
                  If an author you support signs with a hybrid or service
                  publisher, or self publishes, it is the author's obligation to
                  fulfill pre-order copies.
                </ColoredLI>
              </ColoredList>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    )
  }
}

export default HowWeWorkPage
