import React, { Component } from 'react'

import styled from 'styled-components'
import * as PropTypes from 'prop-types'

import ConversationList from './ConversationList'
import MessageList from './MessageList'
import SendMessageForm from './SendMessageForm'

const OuterFlexContainer = styled.div`
  display: flex;
  height: 100%;
`

const InnerFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export default class DataContainer extends Component {
  static propTypes = {
    data: PropTypes.shape({
      activeUser: PropTypes.object,
      conversations: PropTypes.array,
    }),
  }

  constructor(props) {
    super(props)
    this.state = {
      activeConversation: props.data.conversations
        ? props.data.conversations[0]
        : null,
    }
  }

  changeActiveConversation = (e, conversation) => {
    e.preventDefault()
    this.setState({ activeConversation: conversation })
  }

  render() {
    const { activeUser, conversations } = this.props.data
    const { activeConversation } = this.state
    return (
      <OuterFlexContainer>
        <ConversationList
          conversations={conversations}
          changeActiveConversation={this.changeActiveConversation}
          activeConversationId={activeConversation ? activeConversation.id : ''}
          activeUser={activeUser}
        />
        <InnerFlexContainer>
          {activeConversation && (
            <MessageList
              activeUserId={activeUser ? activeUser.id : null}
              messages={activeConversation.messages}
            />
          )}
          {activeConversation && (
            <SendMessageForm conversationId={activeConversation.id} />
          )}
        </InnerFlexContainer>
      </OuterFlexContainer>
    )
  }
}
