import React, { Component } from 'react'
import { theme } from '../../theme'
import { Div, H2 } from '../common'
import { Col, Container, Row } from '../bs'

export default class HWWHeadline extends Component<{
  backgroundColor?: theme.themeColors
  id?: string
}> {
  render() {
    return (
      <Div
        backgroundColor={this.props.backgroundColor}
        style={{ marginBottom: 30 }}
      >
        <Container>
          <Row>
            <Col mdOffset={2}>
              <H2
                color={theme.white}
                uppercase
                fontSize={theme.fontSizeH1}
                id={this.props.id}
                style={{ marginBottom: 22, marginTop: 22 }}
              >
                {this.props.children}
              </H2>
            </Col>
          </Row>
        </Container>
      </Div>
    )
  }
}
