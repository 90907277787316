import React, { ChangeEventHandler } from 'react'
import styled from '@emotion/styled'
import { FieldValue } from './types'

const StyledInput = styled.input``

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  onChangeValue: (name: string, value: FieldValue) => void
}

function Input(props: Props) {
  const { onChangeValue, className, ...other } = props
  return (
    <StyledInput
      {...other}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onChangeValue(e.target.name, e.target.value)
      }
      className={`${className} form-control`}
    />
  )
}

export default Input
