import React from 'react'
import { Label } from '../common'

interface Props
  extends React.DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  > {
  inline?: boolean
}

export default function Radio(props: Props) {
  const labelProps = {
    htmlFor: props.htmlFor,
  }
  if (props.inline)
    return (
      <div style={{ display: 'block' }}>
        <Label {...labelProps} className={'radio-inline'}>
          {props.children}
        </Label>
      </div>
    )
  return (
    <div className={'radio'}>
      <Label {...labelProps}>{props.children}</Label>
    </div>
  )
}
