import React, { Component } from 'react'
import { FieldValue, SelectChoice } from './types'

interface Props {
  id?: string
  value?: FieldValue | string[]
  onChangeValue: (name: string, value: FieldValue) => void
  choices: SelectChoice[]
  placeholder?: string
}

interface SelectProps
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  onChangeValue: (name: string, value: FieldValue) => void
}
const Select = (props: SelectProps) => {
  const { onChangeValue, ...other } = props
  return (
    <select
      {...other}
      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
        onChangeValue(e.target.name, e.target.value)
      }
      className={`${props.className ? props.className : ''} form-control`}
    />
  )
}

class DropDown extends Component<Props> {
  render() {
    const { onChangeValue, choices, value, placeholder, id } = this.props
    const emptyLabel = placeholder ? placeholder : '-----------------'
    return (
      <Select
        value={value}
        name={id}
        id={id}
        className={'form-control'}
        onChangeValue={onChangeValue}
      >
        {placeholder && <option>{emptyLabel}</option>}
        {choices.map((choice: SelectChoice) => (
          <option key={choice.value} value={choice.value}>
            {choice.label}
          </option>
        ))}
      </Select>
    )
  }
}

export default DropDown
