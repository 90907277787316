import React from 'react'
import styled from '@emotion/styled'
import * as theme from '../../theme/theme'

export interface TypoBaseProps {
  color?: theme.themeColors
  backgroundColor?: theme.themeColors
  fontSize?: theme.fontSizes
  fontFamily?: theme.fontFamilies
  uppercase?: boolean
}

export default styled('div')<TypoBaseProps>({}, (props: TypoBaseProps) => ({
  color: props.color,
  backgroundColor: props.backgroundColor,
  fontSize: props.fontSize,
  fontFamily: props.fontFamily,
  textTransform: props.uppercase ? 'uppercase' : 'none',
}))
