import React from 'react'

import gql from 'graphql-tag'
import * as PropTypes from 'prop-types'
import {Mutation} from 'react-apollo/index'
import styled from 'styled-components'

import {GET_CONVERSATIONS} from './MessengerApp'

const CREATE_MESSAGE = gql`
  mutation($text: String!, $conversation: ID!) {
    createMessage(messageData: {text: $text}, conversation: $conversation) {
      status,
      message {
        id
        user {
          id
          image
        }
        date
        text
        attachment
      },
      formErrors,
    }
  }
`

const Form = styled.form`
  display: flex;
`

const SendButton = styled.a`
  text-transform: uppercase;
  line-height: 24px;
  display: block;
`

const UserInput = styled.textarea`
  padding: 2rem;
  line-height: 24px;
  border: ${props => props.error ? '1px solid red' : 'none'};
  :focus {
      outline: none;
  }
`


class SendMessageForm extends React.PureComponent {
  static propTypes = {
    conversationId: PropTypes.string,
  }

  handleChange = (event) => {
    event.target.rows = 1 // Reset
    // Line height: 24px
    const currentRows = ~~((event.target.scrollHeight - 40) / 24)
    event.target.rows = currentRows > 10 ? 10 : currentRows
  }

  update = (cache, createMessage) => {
    const {conversations, activeUser} = cache.readQuery({query: GET_CONVERSATIONS})
    for (const conversation of conversations) {
      if (conversation.id === this.props.conversationId) {
        conversation.messages = conversation.messages.concat(createMessage.message)
        break
      }
    }
    cache.writeQuery({
      query: GET_CONVERSATIONS,
      data: {conversations: conversations, activeUser: activeUser},
    })
  }

  render() {
    let input
    return (
      <Mutation
        mutation={CREATE_MESSAGE}
        update={(cache, {data: { createMessage }}) => this.update(cache, createMessage)}
        onCompleted={() => {
          input.value = ''
          input.rows = 1
        }}
      >
        {(createMessage, { loading, error }) => (
          <Form
            className="mb-0"
            ref={(el) => {
              this.form = el
            }}
            onSubmit={e => {
              e.preventDefault()
              createMessage({
                variables: {
                  text: input.value,
                  conversation: this.props.conversationId,
                }
              })
            }}
          >
            <UserInput
              ref={node => {
                input = node
              }}
              placeholder="Write a message"
              rows="1"
              disabled={loading}
              error={error}
              onChange={this.handleChange}
              onKeyDown={(e) => {
                if (e.keyCode === 13 && e.shiftKey === false) {
                  e.preventDefault()
                  // Fire the onSubmit event only
                  this.form.dispatchEvent(new Event('submit'))
                }
              }}
            />
            <SendButton>Send</SendButton>
          </Form>
        )}
      </Mutation>
    )
  }
}

export default SendMessageForm
