import React from 'react'
import TypoBase from './common/TypoBase'
import styled from '@emotion/styled'
import { fontFamilyMonospace } from '../theme/theme'

const MonoLabel = TypoBase.withComponent('span')

export default styled(MonoLabel)`
  font-family: ${fontFamilyMonospace};
  white-space: nowrap;
`
