export function getBaseUrl(): string {
  return process.env.REACT_APP_API_URL || 'http://localhost:8000/'
}

export function getGraphQLEndpoint(): string {
  return `${getBaseUrl()}gql/`
}

export function isPrerendering(): boolean {
  return window.navigator.userAgent === 'ReactSnap'
}

export function getCookie(cookieName: string): string {
  const name = cookieName + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export function isLoggedIn(): boolean {
  return !!getCookie('sessionid')
}

export const numberStyles = {
  currency: 'currency',
  decimal: 'decimal',
}
export function numberFormat(
  value: number,
  style: string,
  currency = 'USD',
  locale = 'en-US'
) {
  return new Intl.NumberFormat(locale, {
    style,
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value)
}
