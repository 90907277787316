import React from 'react'
import styled from '@emotion/styled'
import { FieldValue } from './types'

const StyledTextArea = styled.textarea``
interface Props
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  onChangeValue: (name: string, value: FieldValue) => void
}

export default function TextArea(props: Props) {
  const { onChangeValue, ...other } = props
  return (
    <StyledTextArea
      {...other}
      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
        onChangeValue(e.target.name, e.target.value)
      }
    />
  )
}
