import React, { Component } from 'react'
import styled from '@emotion/styled'
import * as theme from '../../theme/theme'

const SearchWrapper = styled.div`
  border: 1px solid ${theme.pubPrimary4};
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
`

const ClearButton = styled.button``
const Input = styled.input`
  border: none;
  outline: none;
  box-shadow: none;
  display: inline-block;
  font-size: 16px;
  :focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
`

class SearchInput extends Component<{
  onChange: (e: React.FormEvent<HTMLInputElement>) => void
  value: string
  onClickClear: () => void
}> {
  render() {
    return (
      <SearchWrapper>
        <i className="fas fa-search" style={{ color: theme.pubPrimary4 }} />
        <Input
          onChange={this.props.onChange}
          type="text"
          value={this.props.value}
          className="form-control"
          placeholder={'Search by keyword'}
        />
        {this.props.value.length > 0 && (
          <ClearButton
            className="btn btn-transparent"
            onClick={this.props.onClickClear}
          >
            <i className="fas fa-times" style={{ color: theme.pubPrimary4 }} />
          </ClearButton>
        )}
      </SearchWrapper>
    )
  }
}

export default SearchInput
