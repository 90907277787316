import React, { Component } from 'react'
import { EBookType } from '../../generated/graphql'
import EBook from './EBook'
import LoadingSpinner, { LoadingSpinnerSizes } from '../common/LoadingSpinner'
import { tabs } from './ProposalsPage'
import { getScreenSize, screenSize } from '../../theme/theme'

interface EBookGridProps {
  ebooks?: EBookType[]
  loading: boolean
  onPrevPageClick: () => void
  onNextPageClick: () => void
  hasPrev: boolean
  hasNext: boolean
  activeTab: tabs
}
export default class EBookGrid extends Component<EBookGridProps> {
  groupEbooks = (ebooks?: EBookType[]): EBookType[][] => {
    const columns = getScreenSize() === screenSize.sm ? 2 : 3
    if (!ebooks) return []
    const rows: EBookType[][] = []
    while (ebooks.length > 0) {
      const row: EBookType[] = []
      while (row.length < columns && ebooks.length > 0) {
        row.push(ebooks.shift() as EBookType)
      }
      rows.push(row)
    }
    return rows
  }
  render() {
    return (
      <div className="container">
        {this.groupEbooks(this.props.ebooks).map(
          (row: EBookType[], index: number) => (
            <div className={'row'} key={index}>
              {row.map((ebook: EBookType) => (
                <EBook
                  key={ebook.id}
                  ebook={ebook}
                  tab={this.props.activeTab}
                />
              ))}
            </div>
          )
        )}
        {this.props.loading && (
          <div className="row">
            <div className="col-lg-12 text-center" style={{ marginBottom: 30 }}>
              <LoadingSpinner size={LoadingSpinnerSizes.large} />
            </div>
          </div>
        )}
          <div className="row">
            <div className="col-lg-12 text-center">
              {this.props.hasPrev && (
                <button
                  className="btn btn-primary"
                  onClick={this.props.onPrevPageClick}
                  style={{ marginBottom: 40, marginRight: 10 }}
                >
                  previous page
                </button>
              )}
              {this.props.hasNext && (
              <button
                className="btn btn-primary"
                onClick={this.props.onNextPageClick}
                style={{ marginBottom: 40 }}
              >
                next page
              </button>
              )}
            </div>
          </div>
      </div>
    )
  }
}
