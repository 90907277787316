import React, { Component } from 'react'
import { BookCategoryGroup, BookCategoryType } from '../../generated/graphql'
import styled from '@emotion/styled'
import LoadingSpinner, { LoadingSpinnerSizes } from '../common/LoadingSpinner'
import * as theme from '../../theme/theme'

const buttonHeight = 50
const SelectedButton = styled.button`
  border-color: #fff;
  height: ${buttonHeight}px;
  padding: 0 30px;
  margin: 10px 20px 10px 0;
  background-color: #fff;
  font-size: 22px;
`
const Button = styled.button`
  border-color: #fff;
  height: ${buttonHeight}px;
  padding: 0 20px;
  margin: 10px 20px 10px 0;
  font-size: 12px;
`
const Label = styled.span`
  text-transform: uppercase;
  font-weight: 700;
  font-family: ${theme.fontFamilySansSerif} !important;
`
const Col = styled.div`
  padding-bottom: 30px;
  padding-top: 30px;
`
const SelectedGroup = styled.p`
  font-size: 22px;
  font-weight: 700;
`
const CollapseButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  color: white;
  font-size: 20px;
  margin-top: 10px;
  font-weight: 700;
`

interface EBookCategoriesFilterProps {
  categories: BookCategoryType[]
  selectedCategories: number[]
  onCategoryClick: (category: number) => void
  onSelectedCategoryClick: (category: number) => void
  group?: BookCategoryGroup
  loading: boolean
  collapsed: boolean
  toggleCollapsed: () => void
}

class EBookCategoriesFilter extends Component<
  EBookCategoriesFilterProps
> {

  getName = (categoryId: number): string => {
    const category = this.props.categories.find(
      category => category.id === categoryId.toString()
    )
    return category ? category.name : ''
  }

  render() {
    const {
      loading,
      onSelectedCategoryClick,
      selectedCategories,
      group,
      categories,
      onCategoryClick,
      collapsed,
      toggleCollapsed
    } = this.props
    return (
      <div className={'bg-pub-primary2'} style={{ paddingBottom: 30 }}>
        {loading && group && (
          <div className="text-center">
            <LoadingSpinner inverted size={LoadingSpinnerSizes.large} />
          </div>
        )}
        {!loading && (
          <React.Fragment>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  {selectedCategories.map((category: number) => (
                    <SelectedButton
                      key={category}
                      onClick={() => onSelectedCategoryClick(category)}
                      className="btn"
                    >
                      <Label className="color-primary1 font-family-sans-serif">
                        {' '}
                        <i className="fas fa-times" /> {this.getName(category)}
                      </Label>
                    </SelectedButton>
                  ))}
                </div>
              </div>
              {group && (
                <div className="row">
                  <div className="col-lg-12">
                    {!collapsed && (
                      <CollapseButton
                        onClick={ toggleCollapsed }
                        className="font-family-monospace"
                      >
                        Collapse <i className="fas fa-caret-up" />
                      </CollapseButton>
                    )}
                    {collapsed && (
                      <CollapseButton
                        onClick={toggleCollapsed}
                        className="font-family-monospace"
                      >
                        Open <i className="fas fa-caret-down" />
                      </CollapseButton>
                    )}
                  </div>
                </div>
              )}
              {!collapsed && (
                <React.Fragment>
                  <div className="row">
                    <Col className="col-lg-12">
                      {categories.map((category: BookCategoryType) => {
                        if (
                          selectedCategories.indexOf(parseInt(category.id)) ===
                          -1
                        )
                          return (
                            <Button
                              key={category.id}
                              onClick={() =>
                                onCategoryClick(parseInt(category.id))
                              }
                              className="btn-pub-primary2"
                            >
                              <Label>{category.name}</Label>
                            </Button>
                          )
                      })}
                    </Col>
                  </div>
                  {group && (
                    <div className="row">
                      <div className="col-lg-12">
                        <SelectedGroup className="color-primary1 pull-right">
                          {group === 'NONFICTION' ? 'NON-FICTION' : 'FICTION'}
                        </SelectedGroup>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
}

export default EBookCategoriesFilter
