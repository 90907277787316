import React from 'react'
import TypoBase from './TypoBase'
import styled from '@emotion/styled'
import { themeColors } from '../../theme/theme'

const ColoredList = styled(TypoBase.withComponent('ol'))`
  counter-reset: ol;
  list-style: none;
  padding: 0;
`

export default class OrderedList extends React.Component<{
  bulletColor: themeColors
}> {
  render() {
    return (
      <ColoredList>
        {
          // @ts-ignore
          this.props.children && this.props.children.length && this.props.children.map((child, index) =>
            React.cloneElement(child, {
              key: index,
              bulletColor: this.props.bulletColor,
            })
          )
        }
      </ColoredList>
    )
  }
}
