import React, { Component } from 'react'
import TypoBase, { TypoBaseProps } from './TypoBase'
import styled from '@emotion/styled'
import { themeColors } from '../../theme/theme'
import { theme } from '../../theme'

interface LIProps extends TypoBaseProps {
  bulletColor?: themeColors
}

const ColoredLI = styled(TypoBase.withComponent('li'))<LIProps>`
  counter-increment: ol;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
  &::before {
    content: counter(ol) ' ';
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-right: 10px solid ${(props: LIProps) => props.bulletColor};
    padding-right: 15px;
    margin-right: 15px;
    color: ${(props: LIProps) => props.bulletColor};
    font-family: ${theme.fontFamilySansSerif};
    font-size: ${theme.fontSizes.fontSizeBigHeading2}px;
    font-weight: 500;
  }
`

export default class StyledLI extends Component<LIProps> {
  render() {
    return (
      <ColoredLI {...this.props}>
        <div>{this.props.children}</div>
      </ColoredLI>
    )
  }
}
