export type Maybe<T> = T | null
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any
}

export enum BookCategoryGroup {
  /** Fiction */
  Fiction = 'FICTION',
  /** Nonfiction */
  Nonfiction = 'NONFICTION',
}

export type BookCategoryType = {
  __typename?: 'BookCategoryType'
  id: Scalars['ID']
  name: Scalars['String']
  colour: Scalars['String']
  group: BookCategoryGroup
  position: Scalars['Int']
}

export type ConversationType = {
  __typename?: 'ConversationType'
  id: Scalars['ID']
  users?: Maybe<Array<Maybe<UserProfileType>>>
  archivedBy?: Maybe<Array<Maybe<UserProfileType>>>
  notified?: Maybe<Array<Maybe<UserProfileType>>>
  unreadBy?: Maybe<Array<Maybe<UserProfileType>>>
  readByAll: Scalars['DateTime']
  messages?: Maybe<Array<Maybe<MessageType>>>
}

export type CreateMessage = {
  __typename?: 'CreateMessage'
  status?: Maybe<Scalars['Int']>
  message?: Maybe<MessageType>
  formErrors?: Maybe<Scalars['String']>
}

export type DynamicContentType = {
  __typename?: 'DynamicContentType'
  identifier?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
}

export type EBookStatsType = {
  __typename?: 'EBookStatsType'
  featured?: Maybe<Scalars['Int']>
  live?: Maybe<Scalars['Int']>
  inProgress?: Maybe<Scalars['Int']>
  published?: Maybe<Scalars['Int']>
  products?: Maybe<Scalars['Int']>
}

export type EBookType = {
  __typename?: 'EBookType'
  id: Scalars['ID']
  profile: UserProfileType
  title: Scalars['String']
  slug: Scalars['String']
  shortSlug: Scalars['String']
  subtitle: Scalars['String']
  category: BookCategoryType
  subcategory: Scalars['String']
  summary: Scalars['String']
  image?: Maybe<Scalars['String']>
  background?: Maybe<Scalars['String']>
  publisher?: Maybe<PublisherType>
  linkToAmazon: Scalars['String']
  featured: Scalars['Boolean']
  url: Scalars['String']
  preorders?: Maybe<Scalars['Int']>
  fundedUsd?: Maybe<Scalars['Int']>
  fundedEur?: Maybe<Scalars['Int']>
  readers?: Maybe<Scalars['Int']>
  isRunning?: Maybe<Scalars['Boolean']>
  daysLeft?: Maybe<Scalars['Int']>
  activeProduct?: Maybe<Scalars['Boolean']>
}

export type MessageData = {
  text?: Maybe<Scalars['String']>
}

export type MessageType = {
  __typename?: 'MessageType'
  id: Scalars['ID']
  user: UserProfileType
  conversation: ConversationType
  date: Scalars['DateTime']
  text: Scalars['String']
  attachment?: Maybe<Scalars['String']>
}

export type Mutations = {
  __typename?: 'Mutations'
  pitchBook?: Maybe<PitchBook>
  createMessage?: Maybe<CreateMessage>
}

export type MutationsPitchBookArgs = {
  category?: Maybe<Scalars['Int']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  reference?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  agent?: Maybe<Scalars['Int']>
  summary?: Maybe<Scalars['String']>
  assignAgent?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}

export type MutationsCreateMessageArgs = {
  conversation?: Maybe<Scalars['ID']>
  messageData?: Maybe<MessageData>
  initialUser?: Maybe<Scalars['ID']>
}

export type PaginatedEBooksType = {
  __typename?: 'PaginatedEBooksType'
  ebooks?: Maybe<Array<Maybe<EBookType>>>
  hasNext?: Maybe<Scalars['Boolean']>
  hasPrev?: Maybe<Scalars['Boolean']>
}

export type PaginatedEBooksTypeEbooksArgs = {
  page: Scalars['Int']
  group?: Maybe<Scalars['String']>
  tab?: Maybe<Scalars['String']>
  ordering?: Maybe<Scalars['String']>
  search?: Maybe<Scalars['String']>
  categoryIDs?: Maybe<Array<Maybe<Scalars['String']>>>
  contest?: Maybe<Scalars['String']>
}

export type PitchBook = {
  __typename?: 'PitchBook'
  url?: Maybe<Scalars['String']>
  errors?: Maybe<Scalars['String']>
}

export type PublisherType = {
  __typename?: 'PublisherType'
  id: Scalars['ID']
  name: Scalars['String']
  description: Scalars['String']
  website: Scalars['String']
  image: Scalars['String']
}

export type ProductType = {
  __typename?: 'ProductType'
  id: Scalars['ID']
  active: Scalars['Boolean']
}

export type Queries = {
  __typename?: 'Queries'
  paginatedEbooks?: Maybe<PaginatedEBooksType>
  categories?: Maybe<Array<Maybe<BookCategoryType>>>
  tabStats?: Maybe<EBookStatsType>
  ebookPartial?: Maybe<EBookType>
  recentDeals?: Maybe<Array<Maybe<EBookType>>>
  conversations?: Maybe<Array<Maybe<ConversationType>>>
  activeUser?: Maybe<UserProfileType>
  agents?: Maybe<Array<Maybe<UserProfileType>>>
  dynamicContent?: Maybe<DynamicContentType>
}

export type QueriesPaginatedEbooksArgs = {
  page: Scalars['Int']
  group?: Maybe<Scalars['String']>
  tab?: Maybe<Scalars['String']>
  ordering?: Maybe<Scalars['String']>
  search?: Maybe<Scalars['String']>
  categoryIDs?: Maybe<Array<Maybe<Scalars['String']>>>
  contest?: Maybe<Scalars['String']>
}

export type QueriesCategoriesArgs = {
  group?: Maybe<Scalars['String']>
}

export type QueriesTabStatsArgs = {
  group?: Maybe<Scalars['String']>
  categoryIDs?: Maybe<Array<Maybe<Scalars['String']>>>
  contest?: Maybe<Scalars['String']>
}

export type QueriesEbookPartialArgs = {
  slug?: Maybe<Scalars['String']>
}

export type QueriesDynamicContentArgs = {
  identifier?: Maybe<Scalars['String']>
  defaultContent?: Maybe<Scalars['String']>
}

export type UserProfileType = {
  __typename?: 'UserProfileType'
  id: Scalars['ID']
  image?: Maybe<Scalars['String']>
  background?: Maybe<Scalars['String']>
  shortBio: Scalars['String']
  mediumBio: Scalars['String']
  website: Scalars['String']
  phone?: Maybe<Scalars['String']>
  fullName?: Maybe<Scalars['String']>
  url: Scalars['String']
}
