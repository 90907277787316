// ==============================================
// SCREEN SIZES AND BREAKPOINTS
// ==============================================
export enum screenSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

export const breakpoints = {
  [screenSize.xs]: 480,
  [screenSize.sm]: 758,
  [screenSize.md]: 992,
  [screenSize.lg]: 1200,
}
export const screenTablet = breakpoints[screenSize.sm]

export const getScreenSize = (): screenSize => {
  if (window.innerWidth >= breakpoints[screenSize.lg]) {
    return screenSize.lg
  }
  if (window.innerWidth >= breakpoints[screenSize.md]) {
    return screenSize.md
  }
  if (window.innerWidth >= breakpoints[screenSize.sm]) {
    return screenSize.sm
  }
  return screenSize.xs
}

export const containerPadding = 20
// ==============================================
// TYPOGRAPHY
// ==============================================

export const fontFamilySansSerif =
  '"Rubik", "Helvetica Neue", Helvetica, Arial, sans-serif'
export const fontFamilySerif = 'Georgia, "Times New Roman", Times, serif'
export const fontFamilyMonospace = '"Space Mono", "Courier New", monospace'
export const fontFamilyBase = fontFamilySerif
export type fontFamilies =
  typeof fontFamilySansSerif
  | typeof fontFamilySerif
  | typeof fontFamilyMonospace
  | typeof fontFamilyBase

export const fontSizeBase = 16
export const fontSizeLarge = Math.ceil(fontSizeBase * 1.25) // ~18px
export const fontSizeSmall = Math.ceil(fontSizeBase * 0.85) // ~12px
export const fontSizeBigHeading = Math.ceil(fontSizeBase * 3.5) // ~56px
export const fontSizeBigHeading2 = Math.ceil(fontSizeBase * 2.2) // ~35px
export const fontSizeBigLead = Math.ceil(fontSizeBase * 1.9) // ~30px
export const fontSizeH1 = Math.ceil(fontSizeBase * 2.6) // ~36px
export const fontSizeH2 = Math.ceil(fontSizeBase * 2.15) // ~30px
export const fontSizeH3 = Math.ceil(fontSizeBase * 1.7) // ~24px
export const fontSizeH4 = Math.ceil(fontSizeBase * fontSizeLarge) // ~18px
export const fontSizeH5 = fontSizeBase // 14px
export const fontSizeH6 = Math.ceil(fontSizeBase * fontSizeSmall) // ~24px
export const fontSizes = {
   fontSizeBase,
   fontSizeLarge,
   fontSizeSmall,
   fontSizeBigHeading,
   fontSizeBigHeading2,
   fontSizeBigLead,
   fontSizeH1,
   fontSizeH2,
   fontSizeH3,
   fontSizeH4,
   fontSizeH5,
   fontSizeH6,
}
export type fontSizes =
  | typeof fontSizeBase
  | typeof fontSizeLarge
  | typeof fontSizeSmall
  | typeof fontSizeBigHeading
  | typeof fontSizeBigHeading2
  | typeof fontSizeBigLead
  | typeof fontSizeH1
  | typeof fontSizeH2
  | typeof fontSizeH3
  | typeof fontSizeH4
  | typeof fontSizeH5
  | typeof fontSizeH6

// ==============================================
// COLORS
// ==============================================
export const black = '#000000'
export const grayBase = black
export const grayDarker = '#222'
export const grayDark = '#333'
export const gray = '#555'
export const grayLight = '#777'
export const grayLighter = '#eee'
export const white = '#ffffff'
export type grayScale =
  | typeof black
  | typeof grayBase
  | typeof grayDarker
  | typeof grayDark
  | typeof gray
  | typeof grayLight
  | typeof grayLighter
  | typeof white

export const pubPrimary1 = '#57e2c1'
export const pubPrimary2 = '#1e2d5e'
export const pubPrimary3 = '#50c6db'
export const pubPrimary4 = '#9f9c9c'
export const pubSecondary1 = '#f24c58'
export const pubSecondaryLight1 = '#f99fa1'
export const pubSecondary2 = '#3769b0'
export const pubSecondaryLight2 = '#bbf7f7'
export const pubSecondary3 = '#efcd34'
export const pubSecondaryLight3 = '#ffface'
export const pubSecondary4 = '#f98f39'
export const pubSecondary5 = '#82539e'
export type brandColors =
  | typeof pubPrimary1
  | typeof pubPrimary2
  | typeof pubPrimary3
  | typeof pubPrimary4
  | typeof pubSecondary1
  | typeof pubSecondaryLight1
  | typeof pubSecondary2
  | typeof pubSecondaryLight2
  | typeof pubSecondary3
  | typeof pubSecondaryLight3
  | typeof pubSecondary4
  | typeof pubSecondary5

export const brandPrimary = pubPrimary1
export const brandSuccess = '#78A642'
export const brandInfo = pubPrimary3
export const brandWarning = pubSecondary3
export const brandDanger = pubSecondary1
export const brandSubtle = '#EEEEEE'
export const textColor = grayDark
export type functionalColors =
  | typeof brandPrimary
  | typeof brandSuccess
  | typeof brandInfo
  | typeof brandWarning
  | typeof brandDanger
  | typeof brandSubtle
  | typeof textColor

export const yellow = '#FFFD67'
export const amazon = '#FF9900'
export const facebook = '#3b5998'
export const google = '#4285F4'
export const linkedin = '#0077B5'
export const twitter = '#55ACEE'
export type thirdPartyColors =
  | typeof yellow
  | typeof amazon
  | typeof facebook
  | typeof google
  | typeof linkedin
  | typeof twitter

export type themeColors = grayScale | brandColors | functionalColors | thirdPartyColors
