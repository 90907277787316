import React from 'react'
import styled from '@emotion/styled'
import * as theme from '../../theme/theme'
import { BookCategoryGroup } from '../../generated/graphql'
import { orderByEnum, orderByChoices } from './ProposalsPage'

const fontSize = 22
const Row = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`
const OneLineSpan = styled.span`
  white-space: nowrap;
  position: relative;
  top: 4px;
  margin-bottom: 8px;
  font-size: ${fontSize}px;
  margin-right: 20px;
`
const Button = styled.button`
  font-size: ${fontSize}px;
`
const Select = styled.select`
  font-size: ${fontSize}px;
  max-width: 270px;
  height: unset;
`
const Wrapper = styled.div`
  display: inline-block;
  white-space: nowrap;
  @media (max-width: ${theme.screenTablet}px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`

interface EBookGroupFilterProps {
  onGroupChange: (group?: BookCategoryGroup) => void
  onOrderByChange: (orderBy: orderByEnum) => void
  activeGroup?: BookCategoryGroup
}

export default class EBookGroupFilter extends React.Component<
  EBookGroupFilterProps
> {
  handleGroupButtonClick = (group: BookCategoryGroup) => {
    const newGroup = group !== this.props.activeGroup ? group : undefined
    this.props.onGroupChange(newGroup)
  }

  render() {
    let activeStyle = {
      color: theme.pubPrimary2,
      borderColor: theme.pubPrimary2,
      backgroundColor: theme.white,
    }
    return (
      <div className="bg-pub-primary2">
        <div className="container" style={{ paddingTop: 30 }}>
          <Row className="row">
            <div className="col-md-7">
              <OneLineSpan className="font-family-sans-serif text-uppercase">
                Discover by
              </OneLineSpan>
              <Wrapper>
                <Button
                  className="btn btn-pub-primary2 text-uppercase font-family-sans-serif"
                  style={
                    this.props.activeGroup === BookCategoryGroup.Nonfiction
                      ? activeStyle
                      : {}
                  }
                  onClick={(e: React.MouseEvent) => {
                    ;(e.target as HTMLElement).blur()
                    this.handleGroupButtonClick(BookCategoryGroup.Nonfiction)
                  }}
                >
                  non-fiction
                </Button>
                <OneLineSpan
                  className="font-family-sans-serif text-uppercase"
                  style={{ marginLeft: 20 }}
                >
                  or
                </OneLineSpan>
                <Button
                  className="btn btn-pub-primary2 text-uppercase font-family-sans-serif"
                  style={
                    this.props.activeGroup === BookCategoryGroup.Fiction
                      ? activeStyle
                      : {}
                  }
                  onClick={(e: React.MouseEvent) => {
                    ;(e.target as HTMLElement).blur()
                    this.handleGroupButtonClick(BookCategoryGroup.Fiction)
                  }}
                >
                  fiction
                </Button>
              </Wrapper>
            </div>
            <div className="col-md-5" style={{ justifyContent: 'flex-end' }}>
              <OneLineSpan className="font-family-sans-serif text-uppercase">
                sort by
              </OneLineSpan>
              <Select
                name="sort_by"
                id="id_sort_by"
                className="form-control btn btn-pub-primary2 font-family-sans-serif text-uppercase"
                onChange={e =>
                  this.props.onOrderByChange(e.target.value as orderByEnum)
                }
              >
                {Object.keys(orderByChoices).map(key => (
                  <option key={key} value={key}>
                    {orderByChoices[key]}
                  </option>
                ))}
              </Select>
            </div>
          </Row>
        </div>
      </div>
    )
  }
}
