import React from 'react'
import ReactDOM from 'react-dom'

import { ApolloClient } from 'apollo-client'
import { ApolloProvider } from 'react-apollo'
import { BatchHttpLink } from 'apollo-link-batch-http'
import { createApolloFetch } from 'apollo-fetch'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import MessengerApp from './components/messenger/MessengerApp'
import ProposalsPage from './components/proposals/ProposalsPage'
import * as serviceWorker from './serviceWorker'
import { getGraphQLEndpoint } from './utils'
import HowWeWorkPage from './components/howWeWork/HowWeWorkPage'
import HomeScreen from './components/home/HomeScreen'

const apolloMiddleware = (request, next) => {
  request.options.credentials = 'include'

  // See server.js `apolloMiddleware` for more info:
  request.options.headers = {}
  request.options.headers.clientpath = window.location.pathname
  next()
}

const apolloFetch = createApolloFetch({
  uri: getGraphQLEndpoint(),
})
apolloFetch.batchUse(apolloMiddleware)

const batchHttpLink = new BatchHttpLink({
  fetch: apolloFetch,
})

const errorLink = onError(err => {
  if (err.networkError) {
    console.log('Network Error:')
    console.log(err)
  }
  if (err.graphQLErrors) {
    err.graphQLErrors.forEach(gqlError => {
      console.log('GraphQL Errors:')
      console.log(gqlError)
    })
  }
})
const preloadedState = window.__APOLLO_STATE__
// console.log(preloadedState)
const cache = new InMemoryCache().restore(window.__APOLLO_STATE__)
delete window.__APOLLO_STATE__
const client = new ApolloClient({
  initialState: preloadedState,
  cache,
  link: errorLink.concat(batchHttpLink),
  queryDeduplication: true,
  ssrForceFetchDelay: 200,
})

window.snapSaveState = () => ({
  __APOLLO_STATE__: client.extract(),
})

function AppRouter() {
  return (
    <Router>
      <div>
        <Route path="/" exact component={HomeScreen} />
        <Route path="/how-we-work/" exact component={HowWeWorkPage} />
        <Route path="/:slug/proposals/" exact component={ProposalsPage} />
        <Route path="/proposals/" exact component={ProposalsPage} />
        <Route path="/messages/new/" exact component={MessengerApp} />
      </div>
    </Router>
  )
}

const rootElement = document.getElementById('react-root')
let renderMethod = ReactDOM.render
if (rootElement.hasChildNodes()) renderMethod = ReactDOM.hydrate

renderMethod(
  <ApolloProvider client={client}>
    <AppRouter />
  </ApolloProvider>,
  rootElement
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
