import React, { Component } from 'react'
import { EBookType } from '../../generated/graphql'
import styled from '@emotion/styled'
import * as theme from '../../theme/theme'
import { tabs } from './ProposalsPage'
import { numberFormat, numberStyles } from '../../utils'
import * as PropTypes from "prop-types";

const EBookBorder = styled.div`
  border: 1px solid ${theme.pubPrimary4};
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`
const EBookHeader = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 220px;
`
const EBookTitle = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`
const EBookDescription = styled.p`
  font-size: 14px;
`
const EBookTags = styled.div`
  display: block;
  width: 100%;
`
const EBookBackgroundImage = styled.img`
  display: flex;
  width: 100%;
  height: auto;
`
const ProfileAvatar = styled.img`
  position: relative;
  top: -60px;
  border-radius: 100%;
  border: 3px solid white;
  max-width: 37%;
`
const AuthorName = styled.span`
  font-size: 12px;
  font-weight: 700;
  padding: 10px 0 20px;
  color: ${theme.textColor};
  font-family: ${theme.fontFamilyMonospace};
`
const Title = styled.a`
  font-size: 18px;
  padding: 30px 0 10px;
  font-weight: 700;
  color: ${theme.pubPrimary2};
  font-family: ${theme.fontFamilySerif};
`
const CategoryTag = styled.span`
  color: ${theme.pubPrimary3};
  display: inline-block;
  text-transform: uppercase;
  font-weight: 700;
  margin-right: 15px;
  font-family: ${theme.fontFamilySansSerif};
`
const InfoTag = styled.span`
  color: ${theme.pubPrimary2};
  display: inline-block;
  text-transform: uppercase;
  font-weight: 700;
  margin-right: 15px;
  font-family: ${theme.fontFamilySansSerif};
`
const PublisherTag = styled.span`
  color: ${theme.pubSecondary2};
  display: inline-block;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
  margin-right: 15px;
  font-family: ${theme.fontFamilySansSerif};
`
const DaysLeft = styled.span`
  color: ${theme.pubPrimary4};
  display: inline-block;
  text-transform: uppercase;
  font-weight: 700;
  margin-right: 15px;
  font-family: ${theme.fontFamilySansSerif};
`
const PublisherIcon = styled.img`
  margin-top: 20px;
  max-height: 100px;
`

interface EBookProps {
  ebook: EBookType
  tab?: tabs
}

interface EBookState {
  displayCurrency: string
}

class EBook extends Component<EBookProps, EBookState> {
  private interval?: any

  constructor(props: EBookProps) {
    super(props);
    this.interval = null;
    this.state = {
      displayCurrency: "USD",
    }
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      if (this.state.displayCurrency == 'USD') {
        this.setState({ displayCurrency: "EUR" })
      } else {
        this.setState({ displayCurrency: "USD" })
      }
    }, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getFundedAmount(fundedEur: number | null | undefined, fundedUsd: number | null | undefined) {
    // If there's a EUR amount and if it's currently active, display it. In all other cases display the USD amount
    if (this.state.displayCurrency == 'EUR' && fundedEur && fundedEur > 0) {
      return numberFormat(fundedEur, numberStyles.currency, this.state.displayCurrency);
    } else if (fundedUsd !== undefined && fundedUsd !== null) {
      return numberFormat(fundedUsd, numberStyles.currency, 'USD');
    }
  }

  render() {
    const {
      background,
      summary,
      category,
      featured,
      isRunning,
      profile,
      fundedUsd,
      fundedEur,
      preorders,
      title,
      url,
      publisher,
      daysLeft,
      activeProduct,
    } = this.props.ebook
    const tab = this.props.tab ? this.props.tab : tabs.FEATURED
    const MEDIA_PREFIX =
      process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_MEDIA_HOST
        : ''
    const anonymous =
      'https://publishizer-prod.s3.amazonaws.com/static/img/profile_picture_anonymous.png'
    const noBackground =
      'https://publishizer-prod.s3.amazonaws.com/static/img/no-background.png'
    return (
      <div className="col-sm-6 col-md-4">
        <EBookBorder>
          <EBookHeader href={url}>
            <EBookBackgroundImage
              src={background ? MEDIA_PREFIX + background : noBackground}
            />
            <div style={{ width: '100%', textAlign: 'center' }}>
              <ProfileAvatar
                src={
                  profile.image
                    ? profile.image.startsWith('http')
                      ? profile.image
                      : MEDIA_PREFIX + profile.image
                    : anonymous
                }
              />
            </div>
          </EBookHeader>
          <EBookTitle>
            <Title href={url}>{title}</Title>
            <AuthorName className="text-default">{profile.fullName}</AuthorName>
          </EBookTitle>
          <EBookDescription>{summary}</EBookDescription>
          {!activeProduct && <EBookTags>
            <InfoTag>
              {numberFormat(preorders ? preorders : 0, numberStyles.decimal)}{' '}
              pre-orders
            </InfoTag>
            <InfoTag>
              {this.getFundedAmount(fundedEur, fundedUsd)} funded
            </InfoTag>
          </EBookTags>}
          <EBookTags>
            <CategoryTag>
              {featured && isRunning && '#1 in'} {category.name}
            </CategoryTag>
            {tab === tabs.LIVE && <DaysLeft>{daysLeft} days left</DaysLeft>}
            {publisher && <PublisherTag>{publisher.name}</PublisherTag>}
          </EBookTags>
          {publisher && (
            <div className="text-center">
              <a href={publisher.website}>
                <PublisherIcon src={MEDIA_PREFIX + publisher.image} />
              </a>
            </div>
          )}
        </EBookBorder>
      </div>
    )
  }
}

export default EBook
