import { gql } from 'apollo-client-preset'

export const GET_CATEGORIES = gql`
  query CategoryQuery($group: String) {
    categories(group: $group) {
      name
      group
      colour
      position
      id
    }
  }
`
