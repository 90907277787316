import React from 'react'

import * as PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  overflow-y: scroll;
  width: 350px;
  border-right: 1px solid #f5f5f5;
`

const Wrapper = styled.a`
  display: flex;
  padding: 2rem;
  border-bottom: 1px solid #f5f5f5;
  color: black;
  align-items: center;
  background-color: ${props => props.active ? '#f5f5f5' : '#ffffff'};
  :hover, :focus {
    color: black;
    text-decoration: none;
    background-color: #f5f5f5;
    outline: none;
  }
`

const MessagePreview = styled.p`
  width: 270px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

class ConversationList extends React.Component {
  static propTypes = {
    conversationId: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.state = {
      conversations: props.conversations
    }
  }

  render() {
    return (
      <Container>
        {this.state.conversations && this.state.conversations.map((conversation, i) => {
          let user = conversation.users.filter(user => user.id !== this.props.activeUser.id)[0]
          let firstMessage = conversation.messages[conversation.messages.length - 1].text

          return (
            <Wrapper
              onClick={e => this.props.changeActiveConversation(e, conversation)}
              onFocus={e => this.props.changeActiveConversation(e, conversation)}
              active={this.props.activeConversationId === conversation.id}
              key={i}
              href="#"
            >
              <div className="profile-picture profile-picture-flex">
                <img
                  src={user ? user.image : this.props.activeUser.image}
                  className="img-circle"
                  alt={user ? user.fullName : this.props.activeUser.fullName}
                />
              </div>
              <div>
                <p className="mb-0">
                  <strong>{user ? user.fullName : this.props.activeUser.fullName}</strong>
                </p>
                <MessagePreview className="mb-0">
                  {firstMessage.split('\n')[0]}
                </MessagePreview>
              </div>
            </Wrapper>
          )
        })}
      </Container>
    )
  }
}

export default ConversationList
