import React, { Component } from 'react'
import { RouteComponentProps } from "react-router-dom";
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import EBookGrid from './EBookGrid'
import EBookGroupFilter from './EBookGroupFilter'
import EBookSearchBar from './EBookSearchBar'
import {
  BookCategoryGroup,
  BookCategoryType,
  EBookStatsType,
  EBookType,
} from '../../generated/graphql'
import EBookCategoriesFilter from './EBookCategoriesFilter'
import { isPrerendering } from '../../utils'
import { GET_CATEGORIES } from '../../queries'
import queryString from 'query-string'

export enum tabs {
  FEATURED = 'featured',
  LIVE = 'live',
  INPROGRESS = 'in_progress',
  PUBLISHED = 'published',
  PRODUCTS = 'products',
}

export enum orderByEnum {
  MOST_PRE_ORDERS = '-copies',
  MOST_EARNINGS = '-total',
  MOST_PUBLISHERS_INTERESTED = 'publishers',
  DAYS_LEFT = 'days',
}

export const orderByChoices: { [key: string]: string } = {
  [orderByEnum.MOST_PRE_ORDERS]: 'Most pre-orders',
  [orderByEnum.MOST_EARNINGS]: 'Most earnings',
  [orderByEnum.MOST_PUBLISHERS_INTERESTED]: 'Most publishers interested',
  [orderByEnum.DAYS_LEFT]: 'Days left',
}

interface EBooksData {
  paginatedEbooks?: {
    ebooks: EBookType[]
    hasPrev: boolean
    hasNext: boolean
  }
}

interface EBooksVariables {
  page: number
  tab: tabs
  group?: BookCategoryGroup
  orderBy: orderByEnum
  categoryIDs: string[]
  searchTerm: string
  contest?: string
  publisher?: string
}

export const GET_EBOOKS = gql`
  query EBooksQuery(
    $tab: String!
    $group: String
    $page: Int!
    $searchTerm: String
    $orderBy: String!
    $categoryIDs: [String]
    $contest: String
    $publisher: String
  ) {
    paginatedEbooks(
      tab: $tab
      ordering: $orderBy
      group: $group
      search: $searchTerm
      page: $page
      categoryIDs: $categoryIDs
      contest: $contest
      publisher: $publisher
    ) {
      ebooks {
        title
        slug
        subtitle
        shortSlug
        featured
        isRunning
        id
        background
        image
        summary
        subcategory
        preorders
        fundedUsd
        fundedEur
        readers
        url
        daysLeft
        linkToAmazon
        activeProduct
        publisher {
          name
          image
          website
          id
        }
        category {
          id
          name
          colour
          group
        }
        profile {
          fullName
          image
          url
          background
          shortBio
          mediumBio
          website
          id
        }
      }
      hasPrev
      hasNext
    }
  }
`

interface StatsData {
  tabStats: EBookStatsType
}

interface StatsVariables {
  group?: BookCategoryGroup
  categoryIDs: string[]
  contest?: string
  publisher?: string
}

const GET_TAB_STATS = gql`
  query TabStats($group: String, $categoryIDs: [String], $contest: String, $publisher: String, $products: String) {
    tabStats(group: $group, categoryIDs: $categoryIDs, contest: $contest, publisher: $publisher, products: $products) {
      featured
      live
      inProgress
      published
      products
    }
  }
`

interface CategoriesData {
  categories: BookCategoryType[]
}

interface ProposalsPageState {
  activeTab: tabs
  activeGroup?: BookCategoryGroup
  selectedCategories: number[]
  orderBy: orderByEnum
  searchTerm: string
  page: number
  contest?: string
  collapsed: boolean
}

class ProposalsPage extends Component<RouteComponentProps<any>, ProposalsPageState> {
  firstPage = 1
  state = {
    activeTab: tabs.FEATURED,
    activeGroup: undefined,
    selectedCategories: [],
    orderBy: orderByEnum.MOST_PRE_ORDERS,
    searchTerm: '',
    page: this.firstPage,
    collapsed: true,
    contest: undefined,
  }

  componentDidMount(): void {
    if (window.location.search) {
      const query = queryString.parse(window.location.search, {
        parseNumbers: true,
        arrayFormat: 'comma',
      })
      if (
        query.selectedCategories &&
        !Array.isArray(query.selectedCategories)
      ) {
        // @ts-ignore
        query.selectedCategories = [query.selectedCategories]
      }
      // @ts-ignore
      this.setState(query)
    }
  }

  handleStateChange = (state: any) => {
    this.setState(state, () => this.setUrl(this.state))
  }

  setUrl = (state: ProposalsPageState) => {
    const query: any = {
      ...state,
    }
    delete query.collapsed
    window.history.replaceState(
      null,
      document.title,
      window.location.pathname + '?' +
        queryString.stringify(query, {
          arrayFormat: 'comma',
        })
    )
  }

  handleCategoryClick = (category: number) => {
    this.handleStateChange((state: ProposalsPageState) => ({
      selectedCategories: [...state.selectedCategories, category],
      page: this.firstPage,
    }))
  }

  handleSelectedCategoryClick = (category: number) => {
    this.handleStateChange((state: ProposalsPageState) => ({
      selectedCategories: state.selectedCategories.filter(
        selectedCategory => selectedCategory !== category
      ),
      page: this.firstPage,
    }))
  }

  handleTabChange = (activeTab: tabs) =>
    this.state.activeTab !== activeTab &&
    this.handleStateChange({ activeTab, page: this.firstPage })

  handleGroupChange = (activeGroup?: BookCategoryGroup) => {
    if (this.state.activeGroup !== activeGroup)
      this.handleStateChange({ selectedCategories: [], collapsed: false, page: this.firstPage })
    this.handleStateChange({ activeGroup })
  }

  handleOrderByChange = (orderBy: orderByEnum) =>
    this.state.orderBy !== orderBy &&
    this.handleStateChange({ orderBy, page: this.firstPage })

  handleSearchTermChange = (searchTerm: string) =>
    this.handleStateChange({ searchTerm, page: this.firstPage })

  toggleCollapsed = () =>
    this.setState(state => ({ collapsed: !state.collapsed }))

  render() {
    const {
      activeTab,
      activeGroup,
      orderBy,
      searchTerm,
      selectedCategories,
      page,
      collapsed,
      contest,
    } = this.state
    const publisher = this.props.match.params.slug
    return (
      <React.Fragment>
        <EBookGroupFilter
          onGroupChange={this.handleGroupChange}
          onOrderByChange={this.handleOrderByChange}
          activeGroup={activeGroup}
        />
        <Query<CategoriesData>
          query={GET_CATEGORIES}
          variables={{ group: activeGroup }}
        >
          {({ data, loading }) => (
            <EBookCategoriesFilter
              onCategoryClick={this.handleCategoryClick}
              onSelectedCategoryClick={this.handleSelectedCategoryClick}
              selectedCategories={selectedCategories}
              categories={data && data.categories ? data.categories : []}
              collapsed={collapsed}
              toggleCollapsed={this.toggleCollapsed}
              group={activeGroup}
              loading={loading}
            />
          )}
        </Query>
        <Query<StatsData, StatsVariables>
          query={GET_TAB_STATS}
          skip={isPrerendering()}
          variables={{
            group: activeGroup,
            categoryIDs: selectedCategories,
            contest,
            publisher: publisher,
          }}
        >
          {({ data }) => (
            <EBookSearchBar
              onTabChange={this.handleTabChange}
              tabStats={data ? data.tabStats : undefined}
              searchTerm={searchTerm}
              activeTab={activeTab}
              onSearchTermChange={this.handleSearchTermChange}
            />
          )}
        </Query>
        <Query<EBooksData, EBooksVariables>
          query={GET_EBOOKS}
          skip={isPrerendering()}
          fetchPolicy={'no-cache'}
          variables={{
            tab: activeTab,
            page,
            group: activeGroup,
            searchTerm,
            orderBy,
            categoryIDs: selectedCategories,
            contest,
            publisher: publisher,
          }}
        >
          {({ loading, data }) => (
            <EBookGrid
              loading={loading}
              activeTab={activeTab}
              ebooks={
                data && data.paginatedEbooks ? data.paginatedEbooks.ebooks : []
              }
              onPrevPageClick={() =>
                this.handleStateChange((state: ProposalsPageState) => ({
                  page: state.page - 1,
                }))
              }
              onNextPageClick={() =>
                this.handleStateChange((state: ProposalsPageState) => ({
                  page: state.page + 1,
                }))
              }
              hasPrev={
                data && data.paginatedEbooks
                  ? data.paginatedEbooks.hasPrev
                  : false
              }
              hasNext={
                data && data.paginatedEbooks
                  ? data.paginatedEbooks.hasNext
                  : false
              }
            />
          )}
        </Query>
      </React.Fragment>
    )
  }
}

export default ProposalsPage
