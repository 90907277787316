import React from 'react'
import { EBookStatsType } from '../../generated/graphql'
import styled from '@emotion/styled'
import * as theme from '../../theme/theme'
import { tabs } from './ProposalsPage'
import LoadingSpinner from '../common/LoadingSpinner'
import MonoLabel from '../MonoLabel'
import { fontSizes } from '../../theme/theme'
import SearchInput from '../common/SearchInput'

const Padding = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`
const NavColumn = styled.div`
  margin-bottom: 5px;
  @media (min-width: 1200px) {
    display: inline-block;
    padding-right: 20px;
  }
`
interface TabItemProps {
  children: any
  tabID: tabs
  activeTab: tabs
  onClick: (activeTab: tabs) => void
}

function TabItem(props: TabItemProps) {
  let style = {}
  if (props.activeTab === props.tabID)
    style = {
      borderBottom: `2px solid ${theme.pubPrimary1}`,
    }
  return (
    <a
      href={'#'}
      onClick={e => {
        e.preventDefault()
        props.onClick(props.tabID)
      }}
      style={{
        textDecoration: 'none',
        padding: 2,
        marginTop: 6,
        marginBottom: 6,
        ...style,
      }}
      className={'color-default'}
    >
      <MonoLabel fontSize={fontSizes.fontSizeSmall}>{props.children}</MonoLabel>
    </a>
  )
}

interface EBookSearchBarProps {
  tabStats?: EBookStatsType
  onTabChange: (activeTab: tabs) => void
  onSearchTermChange: (searchTerm: string) => void
  activeTab: tabs
  searchTerm: string
}

interface EBookSearchBarState {
  searchTermCached: string
}

export default class EBookSearchBar extends React.Component<
  EBookSearchBarProps,
  EBookSearchBarState
> {
  private timer?: any

  constructor(props: EBookSearchBarProps) {
    super(props)
    this.state = {
      searchTermCached: props.searchTerm,
    }

    this.timer = undefined
  }

  static getDerivedStateFromProps(
    props: EBookSearchBarProps,
    state: EBookSearchBarState
  ) {
    if (!state.searchTermCached)
      return {
        searchTermCached: props.searchTerm,
      }
    return null
  }

  handleTabItemClick = (activeTab: tabs) => {
    this.props.onTabChange(activeTab)
  }

  handleSearchChange = (searchTerm: string, skipTimeout = false) => {
    this.setState({ searchTermCached: searchTerm })
    clearTimeout(this.timer)
    if (skipTimeout) this.props.onSearchTermChange(searchTerm)
    else
      this.timer = setTimeout(
        () => this.props.onSearchTermChange(searchTerm),
        500
      )
  }

  render() {
    const { tabStats, activeTab } = this.props
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <Padding>
              <div>
                <NavColumn>
                  <TabItem
                    tabID={tabs.FEATURED}
                    activeTab={activeTab}
                    onClick={this.handleTabItemClick}
                  >
                    Featured books{' '}
                    {(tabStats && <strong>{tabStats.featured}</strong>) || (
                      <LoadingSpinner />
                    )}
                  </TabItem>
                </NavColumn>
                <NavColumn>
                  <TabItem
                    tabID={tabs.LIVE}
                    activeTab={activeTab}
                    onClick={this.handleTabItemClick}
                  >
                    Live proposals{' '}
                    {(tabStats && <strong>{tabStats.live}</strong>) || (
                      <LoadingSpinner />
                    )}
                  </TabItem>
                </NavColumn>
                <NavColumn>
                  <TabItem
                    tabID={tabs.INPROGRESS}
                    activeTab={activeTab}
                    onClick={this.handleTabItemClick}
                  >
                    In Progress{' '}
                    {(tabStats && <strong>{tabStats.inProgress}</strong>) || (
                      <LoadingSpinner />
                    )}
                  </TabItem>
                </NavColumn>
                <NavColumn>
                  <TabItem
                    tabID={tabs.PRODUCTS}
                    activeTab={activeTab}
                    onClick={this.handleTabItemClick}
                  >
                    Special Offers{' '}
                    {(tabStats && <strong>{tabStats.products}</strong>) || (
                      <LoadingSpinner />
                    )}
                  </TabItem>
                </NavColumn>
                <NavColumn>
                  <TabItem
                    tabID={tabs.PUBLISHED}
                    activeTab={activeTab}
                    onClick={this.handleTabItemClick}
                  >
                    Published{' '}
                    {(tabStats && <strong>{tabStats.published}</strong>) || (
                      <LoadingSpinner />
                    )}
                  </TabItem>
                </NavColumn>
              </div>
            </Padding>
          </div>
          <div
            className="col-md-3"
            style={{ paddingTop: 8, paddingBottom: 10 }}
          >
            <SearchInput
              onChange={e => this.handleSearchChange(e.currentTarget.value)}
              value={this.state.searchTermCached}
              onClickClear={() => this.handleSearchChange('', true)}
            />
          </div>
        </div>
      </div>
    )
  }
}
