import React from 'react'

import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import TimeAgo from 'react-timeago'

const Wrapper = styled.div`
  overflow-y: scroll;
  flex-grow: 1;
`

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 1rem;
`

const Timestamp = styled.p`
  font-size: 0.9rem;
  margin-top: -5px;
`

class MessageList extends React.Component {
  static propTypes = {
    activeUserId: PropTypes.string,
    messages: PropTypes.array,
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: 'auto' })
  }

  componentDidMount() {
    this.scrollToBottom()
  }

  componentDidUpdate() {
    this.scrollToBottom()
  }

  render() {
    return (
      <Wrapper className="colored-background">
        {this.props.messages.map((message, i) => {
          return (
            <MessageWrapper key={i}>
              <div
                className={`profile-picture profile-picture-flex ${
                  this.props.activeUserId === message.user.id
                    ? 'ml-auto'
                    : 'ml-1'
                }`}
              >
                <img
                  src={message.user.image}
                  className="img-circle"
                  alt={message.user.fullName}
                />
              </div>
              <div className="panel mr-1 mb-0 mt-1">
                <div className="panel-default">
                  <div className="panel-body">
                    <Timestamp className="mb-1 text-right text-muted">
                      <TimeAgo date={message.date} />
                    </Timestamp>
                    {message.text.split('\n').map((item, i) => <p className="mb-0" key={i}>{item}</p>)}
                  </div>
                </div>
              </div>
            </MessageWrapper>
          )
        })}
        <div
          ref={el => {
            this.messagesEnd = el
          }}
        />
      </Wrapper>
    )
  }
}

export default MessageList
