import React, { Component } from 'react'
import { EBookType } from '../../generated/graphql'
import { gql } from 'apollo-client-preset'
import { Query } from 'react-apollo'
import { isPrerendering } from '../../utils'
import LoadingSpinner, { LoadingSpinnerSizes } from '../common/LoadingSpinner'
import Grid from './Grid'

interface EBooksData {
  recentDeals: EBookType[]
}

export const GET_RECENT_DEALS = gql`
  query EBooksQuery {
    recentDeals {
      title
      slug
      subtitle
      shortSlug
      featured
      isRunning
      id
      background
      image
      summary
      subcategory
      preorders
      fundedUsd
      fundedEur
      readers
      url
      daysLeft
      linkToAmazon
      activeProduct
      publisher {
        name
        image
        website
        id
      }
      category {
        id
        name
        colour
        group
      }
      profile {
        fullName
        image
        url
        background
        shortBio
        mediumBio
        website
        id
      }
    }
  }
`

class HomeScreen extends Component {
  render() {
    return (
      <Query<EBooksData>
        query={GET_RECENT_DEALS}
        skip={isPrerendering()}
        fetchPolicy={'no-cache'}
      >
        {({ loading, data }) => {
          return (
            <div>
              {!loading && data && (
                <Grid
                  loading={loading}
                  ebooks={data.recentDeals}
                />
              )}
              {loading && (
                <div className="container">
                  <div className={'text-center'}>
                    <LoadingSpinner size={LoadingSpinnerSizes.large} />
                  </div>
                </div>
              )}
            </div>
          )
        }}
      </Query>
    )
  }
}

export default HomeScreen
