import React from 'react'
import { theme } from '../../theme'

function Panel(props: { children: React.ReactNode | React.ReactNode[] }) {
  return (
    <div
      className={'panel panel-default'}
      style={{ padding: theme.containerPadding }}
    >
      {props.children}
    </div>
  )
}

export default Panel
