import React, { Component } from 'react'
import { EBookType } from '../../generated/graphql'
import EBook from '../proposals/EBook'
import LoadingSpinner, { LoadingSpinnerSizes } from '../common/LoadingSpinner'
import { getScreenSize, screenSize } from '../../theme/theme'

interface GridProps {
  ebooks?: EBookType[]
  loading: boolean
}

interface GridState {
  rows: EBookType[][]
  visibleRows: number
}

export default class Grid extends Component<GridProps, GridState> {
  constructor(props: GridProps) {
    super(props)
    const columns = getScreenSize() === screenSize.sm ? 2 : 3
    const rows: EBookType[][] = []
    if (props.ebooks) {
      while (props.ebooks.length > 0) {
        const row: EBookType[] = []
        while (row.length < columns && props.ebooks.length > 0) {
          row.push(props.ebooks.shift() as EBookType)
        }
        rows.push(row)
      }
    }
    this.state = {
      rows: rows,
      visibleRows: 4,
    }
  }

  showMore = () => {
    if (this.state.visibleRows < this.state.rows.length) {
      this.setState({'visibleRows': this.state.visibleRows + 1})
    }
  }

  render() {
    return (
      <div className="container">
        {this.state.rows.map(
          (row: EBookType[], index: number) => (
            <div className={`row ${index <= this.state.visibleRows - 1 ? '' : 'hidden'}`} key={index}>
              {row.map((ebook: EBookType) => (
                <EBook
                  key={ebook.id}
                  ebook={ebook}
                />
              ))}
            </div>
          )
        )}
        {this.props.loading && (
          <div className="row">
            <div className="col-lg-12 text-center" style={{ marginBottom: 30 }}>
              <LoadingSpinner size={LoadingSpinnerSizes.large} />
            </div>
          </div>
        )}
        {this.state.visibleRows < this.state.rows.length && (
          <div className="text-center">
            <button
              className={'btn btn-primary'}
              onClick={this.showMore}
            >
              Show more
            </button>
          </div>
        )}
        {this.state.visibleRows >= this.state.rows.length && (
          <div className="text-center">
            <a
              className={'btn btn-success'}
              href={'/proposals/?activeTab=featured'}
            >
              See campaigns
            </a>
          </div>
        )}
      </div>
    )
  }
}
